
import { defineComponent, computed, ref, reactive } from 'vue';
import { message, Form } from 'ant-design-vue';
import { mapActions, mapState, useStore } from 'vuex';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import { mobileReg } from '@/utils/illuminate';

import { useRoute, useRouter } from 'vue-router';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { useState } from '@/store';
import { request } from '@/utils/request';
import { CAPTCHA_TYPE } from '@/utils/constants';
import { useCountdown } from '@/components/fragments/countdown';

export default defineComponent({
  name: 'Login',

  components: {
    LockOutlined,
  },

  setup(props, ctx) {
    const store = useStore();
    const state = useState();
    const route = useRoute();
    const router = useRouter();
    const countdownState = useCountdown();

    const submiting = ref(false);
    const showPwd = ref(false);

    const eyeType = computed(() => (showPwd.value ? 'eye-open' : 'eye-closed'));
    const formData = reactive({
      mobile: '',
      verificationCode: '',
      saasLoginPassword: '',
    });

    // 提交登陆
    const formRef = ref<typeof Form>(null as any);

    const codeSending = ref(false);
    const onSendCaptcha = async () => {
      if (countdownState.counting.value || codeSending.value) return;

      try {
        await formRef.value.validate(['mobile']);
      } catch (e: Error & any) {
        return;
      }

      codeSending.value = true;
      try {
        await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send/code'), {
          params: { mobile: formData.mobile, type: CAPTCHA_TYPE.FORGOT_PWD },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        codeSending.value = false;
      }

      countdownState.start();
      message.success('验证码发送完成');
    };

    const sumiting = ref(false);
    const onSubmit = async () => {
      try {
        await formRef.value.validate();
        if (sumiting.value) return;
        sumiting.value = true;
        await request.put(createApiUrl('/newlinkSass/personal/login-user-find-pwd'), formData);
        message.success('密码保存完成');
        router.replace({ name: 'auth.login' });
      } catch (e: Error & any) {
        message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        sumiting.value = false;
      }
    };

    return {
      submiting,
      showPwd,
      eyeType,
      route,

      formRef,
      formData,

      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => {
              if (!mobileReg.test(value)) return Promise.reject('请输入正确的手机号!');
              return Promise.resolve();
            },
            trigger: 'blur',
          },
          { validator: () => Promise.resolve(), trigger: 'change' },
        ],
        verificationCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '验证码错误', trigger: 'blur' },
          { validator: () => Promise.resolve(), trigger: 'change' },
        ],
        saasLoginPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^\S{8,20}$/, message: '密码必须为8-20位', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => {
              const hasNum = [/[a-zA-Z]/, /\d/, /[^a-zA-Z0-9]/].reduce((pre, item) => pre + (item.test(value) ? 1 : 0), 0);
              return hasNum < 2 ? Promise.reject('密码须包含字母、数字、符号至少两种!') : Promise.resolve();
            },
            trigger: 'blur',
          },
          { validator: () => Promise.resolve(), trigger: 'change' },
        ],
      },

      codeSending,
      sendCounting: countdownState.counting,
      sendText: countdownState.currentText,
      onSendCaptcha,

      onSubmit,
    };
  },
});
