
import { defineComponent, computed, onMounted, watch, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { useState } from '@/store';
import { startLoopUserDetail, UserMutationList, startMessageLoop } from '@/store/modules/user';

export default defineComponent({
  name: 'ChoseEnterprise',
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = useState();

    const tempInfo = computed(() => store.state.User.tempInfo);

    const selectIndex = ref(0);

    // 提交
    const onSubmit = async () => {
      await store.dispatch(`User/${UserMutationList.SET_ENTERPRISE}`, state.enterpriseList.value[selectIndex.value]);

      try {
        await store.dispatch('User/code2Token');
      } catch (e: Error & any) {
        if (e.code === 1331) {
          router.push({ name: 'auth.login' });
        }
        return message.error(e.message);
      }

      await router.push(route.query.redirect as string || { name: 'main.overview' });
      await store.dispatch('User/getUserDetail');
      await store.dispatch('User/getUserMenu');
      startLoopUserDetail();
      startMessageLoop();
    };

    onMounted(() => {
      if (!tempInfo.value) router.push('auth.login');
    });

    return {
      selectIndex,
      onSubmit,
    };
  },
});
