
import { defineComponent, computed, ref, reactive, watch, onMounted, getCurrentInstance } from 'vue';
import { message, Form } from 'ant-design-vue';
import { LockOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { codeReg, mobileReg, timeFix } from '@/utils/illuminate';

import { createApiUrl, firstError } from '@/utils/utils';
import { startLoopUserDetail, startMessageLoop } from '@/store/modules/user';
import { useState } from '@/store';
import { request } from '@/utils/request';
import { useCountdown } from '@/components/fragments/countdown';

export default defineComponent({
  name: 'Login',
  components: {
    LockOutlined,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const state = useState();
    const route = useRoute();
    const router = useRouter();
    const countdownState = useCountdown();

    const submiting = ref(false);
    const loginBy = computed(() => (route.name === 'auth.login_by_pwd' ? 'password' : 'code'));

    const formData = reactive({
      mobile: '',
      loginPassword: '',
      verificationCode: '',
    });

    const allEmpty = computed(() => !Object.values(formData).join('').length);

    // 提交登录
    const formRef = ref<typeof Form>(null as any);
    let isForSubmiting = false;
    const rules = computed(() => ({
      mobile: [{
        validator: (rule: any, value: any) => new Promise<void>((resolve, reject) => {
          if (!isForSubmiting && allEmpty.value) {
            // 如果全部为空, 且不是登录的状态下不显示错误
            resolve();
            formRef.value.clearValidate();
          } else if (!value) {
            reject('请输入手机号');
          } else if (!mobileReg.test(value)) {
            reject('请输入正确的手机号');
          } else {
            resolve();
          }
        }),
        trigger: 'blur',
      }],
      loginPassword: [{
        validator: (rule: any, value: any) => new Promise<void>((resolve, reject) => {
          if (!isForSubmiting && allEmpty.value) {
            // 如果全部为空, 且不是登录的状态下不显示错误
            resolve();
            formRef.value.clearValidate();
          } else if (loginBy.value === 'password' && !value) {
            return reject('请输入密码');
          } else {
            resolve();
          }
        }),
        trigger: 'blur',
      }],
      verificationCode: [{
        validator: (rule: any, value: any) => new Promise<void>((resolve, reject) => {
          if (!isForSubmiting && allEmpty.value) {
            // 如果全部为空, 且不是登录的状态下不显示错误
            resolve();
            formRef.value.clearValidate();
          } else if (loginBy.value === 'code' && !value) {
            return reject('请输入验证码');
          } else if (loginBy.value === 'code' && !codeReg.test(value)) {
            return reject('验证码错误');
          } else {
            resolve();
          }
        }),
        trigger: 'blur',
      }],
    }));

    watch(() => formData.mobile, val => {
      if (val.toString().length > 11) formData.mobile = val.toString().slice(0, 11);
    });

    watch(() => formData.loginPassword, val => {
      if (val.toString().length > 20) formData.loginPassword = val.toString().slice(0, 20);
    });

    watch(() => formData.verificationCode, val => {
      if (val.toString().length > 6) formData.verificationCode = val.toString().slice(0, 6);
    });

    // = ---------------------------- = 发送验证码 = ---------------------------- =
    const codeSending = ref(false);
    const onSendCaptcha = async () => {
      if (countdownState.counting.value || codeSending.value) return;

      try {
        isForSubmiting = true;
        await formRef.value.validate(['mobile']);
        isForSubmiting = false;
      } catch (e: Error & any) {
        return;
      }

      codeSending.value = true;
      try {
        await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send'), {
          params: { mobile: formData.mobile },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        codeSending.value = false;
      }

      countdownState.start();
      message.success('验证码已发送');
    };

    const onClearError = (name: string) => {
      formRef.value.clearValidate(name);
    };

    // = ---------------------------- = 提交登陆 = ---------------------------- =
    const loginSuccess = async () => {
      if (state.enterpriseList.value.length > 1) {
        submiting.value = false;
        return router.push({ name: 'auth.chose_enterprise', query: route.query });
      }

      await store.dispatch('User/code2Token');
      await store.dispatch('User/getUserDetail');
      await store.dispatch('User/getInviteUserNum');
      await store.dispatch('User/getUserMenu');
      startLoopUserDetail();
      startMessageLoop();

      message.success(`${timeFix()}，欢迎回来`);
      const redirect = route.query.redirect!;
      if (redirect) {
        router.resolve(redirect as string);
      }
      router.push(route.query.redirect as string || { name: store.state.User.sideMenu[0].name || 'main.overview' });
      submiting.value = false;
    };

    const onSubmit = async () => {
      try {
        isForSubmiting = true;
        await formRef.value.validate();
        isForSubmiting = false;

        submiting.value = true;
        await store.dispatch('User/login', { by: loginBy.value, userInfo: formData });
        loginSuccess();
      } catch (e: Error & any) {
        isForSubmiting = false;
        return message.error(e.errorFields ? firstError(e) : (e.message || '请求出现错误，请稍后再试'));
      } finally {
        submiting.value = false;
      }
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_login_page_view', { page_name: '登录页_浏览' });
    });

    return {
      submiting,
      route,

      formRef,
      formData,

      codeSending,
      sendCounting: countdownState.counting,
      sendText: countdownState.currentText,
      onSendCaptcha,

      rules,
      loginBy,
      onClearError,
      onSubmit,
    };
  },
});
